<div class="vente-panel">
    <form [formGroup]="formGroup" class="d-flex flex-column gap-4 align-items-center justify-content-between">

        <div class="d-flex flex-column gap-1 w-100">
            <label for="comparedValue">{{'suppression.fields.confirmation.label' | transloco : {trueValue: config.data.trueValue} }}</label>
            <input id="comparedValue"
                   type="text"
                   [class]="'w-100'"
                   pInputText
                   [placeholder]="'suppression.fields.confirmation.placeholder' | transloco : {trueValue: config.data.trueValue} "
                   formControlName="comparedValue">
        </div>
        <div class="d-flex gap-3 w-100 align-items-center">
            <p-button
                    [label]="'suppression.buttons.annuler.label' | transloco"
                    type="button" (click)="cancel()"
                    [icon]="'suppression.buttons.annuler.icon' | transloco">
            </p-button>
            <p-button
                    [label]="'suppression.buttons.supprimer.label' | transloco"
                    type="button" (click)="confirmer()"
                    severity="danger"
                    [disabled]="!(config.data.trueValue == formGroup.get('comparedValue')?.value)"
                    [icon]="'suppression.buttons.supprimer.icon' | transloco">
            </p-button>
        </div>
    </form>
</div>
