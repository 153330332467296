import {FullUserDto} from "../../domain/userDtos/FullUserDto";


const PREFIX = ['user'];

export class UpdateUserState {
    static readonly type: string = PREFIX + "UpdateUserState";

    constructor() {
    }
}

