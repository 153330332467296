import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
    selector: 'vente-panel',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {

    formGroup: FormGroup

    constructor(private fb: FormBuilder,
                public ref: DynamicDialogRef,
                public config: DynamicDialogConfig,
                private cdr: ChangeDetectorRef,) {

        this.formGroup = this.fb.group({
            comparedValue: [null, Validators.required],
        })
    }

    cancel() {
        this.ref.close({
            decision: false,
        });
    }

    confirmer() {
        this.ref.close({
            decision: true,
        });
    }
}
