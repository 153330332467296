import { NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslocoRootModule} from './transloco-root.module';
import {registerLocaleData} from "@angular/common";
import {StockService} from "./services/stock.service";
import {RouterOutlet} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UserService} from "./services/user.service";
import {AuthService} from "./services/auth.service";
import {AuthInterceptor} from "./security/auth.interceptor";
import {NgxsModule} from "@ngxs/store";
import {UserState} from "./states/user/user.state";
import {environment} from "../environments/environment";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import {SettingsService} from "./services/settings.service";
import {SettingsState} from "./states/settings/settings.state";
import {ServiceWorkerModule, SwRegistrationOptions} from '@angular/service-worker';
import {MessageService} from "primeng/api";
import {ConfirmationModalComponent} from "./services/confirmation-modal/confirmation-modal.component";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {NavigationService} from "./services/navigation.service";
import {UpdateService} from "./services/update.service";
import {ImageCompressorService} from "./services/image-compressor.service";

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent,
        ConfirmationModalComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslocoRootModule,
        RouterOutlet,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,

        NgxsModule.forRoot([UserState], {developmentMode: !environment.production}),
        NgxsModule.forRoot([SettingsState], {developmentMode: !environment.production}),


        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,//!isDevMode(),
            scope: "/",
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:3000'
        }),
        ReactiveFormsModule,
        ButtonModule,
        InputTextModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
        JwtHelperService,
        StockService,
        UserService,
        AuthService,
        SettingsService,
        StockService,
        MessageService,
        NavigationService,
        UpdateService,
        ImageCompressorService,

        {
            provide: SwRegistrationOptions,
            useFactory: () => ({enabled: location.search.includes('sw=true')}),
        }

    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
