

const PREFIX = ['settings'];


export class LoadSettings {
    static readonly type: string = PREFIX + "LoadSettings";

    constructor() {
    }
}