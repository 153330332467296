import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {combineLatest, forkJoin, map, Observable, retry, skip, timeout} from "rxjs";
import {StockProduct} from "../domain/stockDtos/StockProduct";
import {environment} from "../../environments/environment";
import {VenteDto} from "../domain/stockDtos/VenteDto";
import {PaginatorDto} from "../domain/PaginatorDto";
import {ImageDto} from "../domain/stockDtos/ImageDto";
import {Image} from "primeng/image";
import {flatten} from "@ngneat/transloco";


@Injectable()
export class StockService {
    private STOCK_PATH = "/stock"

    constructor(private http: HttpClient) {
    }

    getStockUnit(unit_id: number): Observable<StockProduct> {
        return this.http.get<StockProduct>(`${environment.apiUrl}${this.STOCK_PATH}/details/${unit_id}`, { withCredentials: true });
    }

    listStockUnits(paginator: PaginatorDto): Observable<StockProduct[]> {
        return this.http.post<StockProduct[]>(`${environment.apiUrl}${this.STOCK_PATH}/list`, {
            skip: paginator.skip || 0,
            limit: paginator.limit || 10,
        },
        { withCredentials: true });
    }

    totalStockUnits(): Observable<number> {
        return this.http.get<number>(`${environment.apiUrl}${this.STOCK_PATH}/total`, { withCredentials: true })
    }

    saveOrUpdateStockUnit(unit: StockProduct): Observable<StockProduct> {
        return this.http.post<StockProduct>(`${environment.apiUrl}${this.STOCK_PATH}/save`, unit, { withCredentials: true });
    }
    
    uploadImage(image: string, stock_id: number): Observable<{image: string}> {
        return this.classicUpload(image, stock_id);
        //return this.chunkUpload(image, stock_id);
    }

    classicUpload(image: string, stock_id: number): Observable<{ image: string }> {
        const formData = new FormData();
        const partSize = image.length/ 7;
        let count = 1;
        for (let i = 0; i < image.length; i += partSize) {
            formData.append('image'+count, image.slice(i, i+ partSize));
            count+=1;
        }

        return this.http.post<{ image: string }>(
                `${environment.apiUrl}${this.STOCK_PATH}/upload/${stock_id}`,
                formData,
                {   withCredentials: true })
                .pipe(timeout(4000), retry(5));
    }

    chunkUpload(image: string, stock_id: number):  Observable<{ image: string }> {
        const initData = new FormData();
        initData.append('image_chunk',"chk" );
        initData.append('position', "-1");
        this.http.post<{ image: string }>(`${environment.apiUrl}${this.STOCK_PATH}/upload/chunk/${stock_id}`,
                initData,
                {   withCredentials: true }).pipe(timeout(2000), retry(2)).subscribe();

        const chunks = [];
        const partSize = image.length/ 6;
        for (let i = 0; i < image.length; i += partSize) {
           chunks.push(image.slice(i, i+ partSize));
        }


        const observables = chunks.map((chunk, index) => {
            const formData = new FormData();
            formData.append('image_chunk',chunk );
            formData.append('position', index.toString());
            return this.http.post<{ image: string }>(`${environment.apiUrl}${this.STOCK_PATH}/upload/chunk/${stock_id}`,
                                        formData,
                                {   withCredentials: true }).pipe(timeout(3000), retry(4));

        })
        return forkJoin(observables).pipe(
                timeout(12000),
                map(responses => {
                    return responses[-1];
                })
        );
    }


    deleteStockUnit(unit_id: number): Observable<Boolean> {
        return this.http.delete<Boolean>(`${environment.apiUrl}${this.STOCK_PATH}/delete/${unit_id}`, { withCredentials: true });
    }

    sellStockUnit(sell: VenteDto): Observable<StockProduct> {
        return this.http.post<StockProduct>(`${environment.apiUrl}${this.STOCK_PATH}/sell`, sell, { withCredentials: true })
    }

    searchStocks(code: number): Observable<StockProduct[]> {
        return this.http.get<StockProduct[]>(`${environment.apiUrl}${this.STOCK_PATH}/search?q=${code}`, { withCredentials: true })
    }
}