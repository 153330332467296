import {Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {UserRoleEnum} from "../domain/enums/UserRoleEnum";
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

    userRole!: UserRoleEnum;
    user_email: string | null = null;

    constructor(private store: Store,
                private jwtService: JwtHelperService) {

        this.updateRoleFromToken();
        this.getUserEmail();
    }

    getUserEmail() {
        const token = this.getToken();
        if (!this.jwtService.isTokenExpired(token)) {
            const decoded_token =  this.jwtService.decodeToken(token);
            this.user_email = decoded_token.email ?? null;
        }
    }




    updateRoleFromToken() {
        const token = this.getToken();
        try {
            if (!this.jwtService.isTokenExpired(token)) {
                const decoded_token =  this.jwtService.decodeToken(token);
                if (decoded_token.role) {
                    this.userRole = decoded_token.role;
                } else {
                    this.userRole = UserRoleEnum.DECONNECTE;
                }
            } else {
                this.userRole = UserRoleEnum.DECONNECTE;
            }
            return this.userRole;
        } catch (err) {
            console.error(err);
            this.userRole = UserRoleEnum.DECONNECTE;
            return this.userRole;
        }
    }

    setDataInLocalStorage(variableName: string, data: any): void {
        localStorage.setItem(variableName, data)
    }

    getDataInLocalStorage(variableName: string): any {
        return localStorage.getItem(variableName);
    }


    setToken(token: string): void {
        this.setDataInLocalStorage('token', token);
    }

    getToken(): string {
        return this.getDataInLocalStorage('token');
    }

    clearStorage(): void {
        localStorage.clear();
    }


    setUserRole(role: UserRoleEnum) {
        this.userRole = role;
    }

    getUserRole() {
        return this.userRole;
    }

    isAdministrator(): boolean {
        return this.userRole ? this.userRole === UserRoleEnum.ADMINISTRATEUR : false;
    }

    isSameUser(email: string): boolean {
        return this.user_email == email;
    }

}