import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
        this.updates.checkForUpdate().then(
            () => {
                window.location.reload();
            }
        );
    }
}