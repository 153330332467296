import {Component, OnInit} from '@angular/core';
import {UpdateService} from "./services/update.service";
import {NavigationService} from "./services/navigation.service";
import {PrimeNGConfig} from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'frontend';

  constructor(private updateService: UpdateService,
              private navigationService: NavigationService,
              private config: PrimeNGConfig) {
  }

    ngOnInit(): void {
      this.config.setTranslation({
         accept: 'Accept',
         reject: 'Reject',
          monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          monthNamesShort: ['Jan.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sep.', 'Oct.', 'Nov.', 'Déc.'],
      });
    }
}
