import {EventEmitter, Injectable} from "@angular/core";
import {NgxImageCompressService} from "ngx-image-compress";
import * as ExifReader from 'exifreader';
import {catchError, from, of, tap} from "rxjs";


@Injectable({providedIn: "root"})
export class ImageCompressorService {

    public newImage: EventEmitter<string> = new EventEmitter<string>();

    constructor(private imageCompress: NgxImageCompressService) {
    }


    compressFile(file: File, fileString: string): void {
        const MAX_BYTE = 2294967;
        from(ExifReader.load(file)).pipe(
                tap(tags => {
                    const orientation: number = (typeof tags.Orientation?.value != "number") ? 1 : tags.Orientation?.value;
                    let ratio = file.size / MAX_BYTE;


                    if (ratio < 1) this.newImage.emit(fileString);
                    else {
                        const x = tags["Image Width"]?.value ?? 1080;
                        const y = tags["Image Height"]?.value ?? 1920;

                        const resizeRatio = Math.min(x / 1080, y / 1920) ?? 1;

                        const newX = x  / resizeRatio;
                        const newY = y / resizeRatio;

                        ratio = ratio * 100;
                        from(this.imageCompress.compressFile(
                                    fileString,
                                    orientation,
                                    ratio,
                                    80,
                                    newX,
                                    newY)
                        ).subscribe({
                                    next: value => {
                                        this.newImage.emit(value);
                                    },
                                    error: err => console.error(err)
                                });
                    }

                }),
                catchError((error) => {
                    return of('Error');
                }),
        ).subscribe();
    }
}