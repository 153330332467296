import {FullUserDto} from "../../domain/userDtos/FullUserDto";
import {UserRoleEnum} from "../../domain/enums/UserRoleEnum";
import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import { UpdateUserState} from "./user.actions";
import {UserService} from "../../services/user.service";
import {catchError, map, Observable, of, take} from "rxjs";

@State<FullUserDto>({
    name: 'user',
    defaults: {
        id: 0,
        email: '',
        lastname: '',
        firstname: '',
        role: UserRoleEnum.DECONNECTE,
    },
})
@Injectable()
export class UserState {
    scope = 'user';

    constructor(private userService: UserService) {
    }

    @Action(UpdateUserState)
    UpdateUserState(ctx: StateContext<FullUserDto>) {
        this.userService.getProfile().pipe(take(1))
                .subscribe({
                    next: userDto => {
                        ctx.patchState({
                            ...userDto
                        });
                    },
                    error: err => {
                        console.error(err);
                    }
                });
    }
}