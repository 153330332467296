import {Action, State, StateContext} from "@ngxs/store";
import {SettingsDto} from "../../domain/SettingsDto";
import {Injectable} from "@angular/core";
import {SettingsService} from "../../services/settings.service";
import {LoadSettings} from "./settings.actions";
import {take, tap} from "rxjs";


@State<SettingsDto>({
    name: 'settings',
    defaults: {
        app_name: '',
        app_logo: '',
        primary_color: '',
        secondary_color: '',
        license_number: 0,
    }
})
@Injectable()
export class SettingsState {
    scope = 'settings';


    constructor(private settingsService: SettingsService) {
    }


    @Action(LoadSettings)
    LoadSettings(ctx: StateContext<SettingsDto>) {
        return this.settingsService.getSettings().pipe(
                take(1),
                tap(settings => {
                    ctx.patchState({
                        ...settings,
                    });
                }),
        );
    }
}