import {Injectable} from "@angular/core";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({providedIn: "root"})
export class NavigationService {
    private history: string[] = [];


    constructor(private router: Router, private location: Location) {

        this.router.events.subscribe((event) => {
            if (event instanceof  NavigationStart && event.url.includes('stock/list')) {
                this.history.push(event.url);
            }
        });
    }

    back(): void {
        const prec_location = this.history.pop();
        console.log(prec_location);
        this.router.navigateByUrl(prec_location ?? "/");
    }
}