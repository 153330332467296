import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {SettingsDto} from "../domain/SettingsDto";


@Injectable()
export class SettingsService {
    private SETTINGS_PATH = "/settings";
    constructor(private http: HttpClient) {
    }


    updateSettings(settings: SettingsDto): Observable<SettingsDto> {
        return this.http.post<SettingsDto>(`${environment.apiUrl}${this.SETTINGS_PATH}/update`, settings, { withCredentials: true });
    }

    getSettings(): Observable<SettingsDto> {
/*
        return this.http.get<SettingsDto>(`${environment.apiUrl}${this.SETTINGS_PATH}`);
*/
        return this.http.get<SettingsDto>(`${environment.apiUrl}${this.SETTINGS_PATH}/`, { withCredentials: true });
    }

}