import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LoginDto} from "../domain/userDtos/LoginDto";
import {environment} from "../../environments/environment";
import {UserDto} from "../domain/userDtos/UserDto";
import {FullUserDto} from "../domain/userDtos/FullUserDto";
import {UpdateUserDto} from "../domain/userDtos/UpdateUserDto";
import {PasswordDto} from "../domain/userDtos/PasswordDto";


@Injectable()
export class UserService {
    private USER_PATH = "/user";
    private LOGIN_PATH = this.USER_PATH + "/token";
    private PROFILE_PATH = this.USER_PATH + "/me";
    private PROFILE_UPDATE = this.USER_PATH + "/update"
    private PROFILE_PASSWORD_UPDATE = this.USER_PATH + "/change-password"

    
    constructor(private http: HttpClient) {
    }

    requestToken(userInfo?: UserDto): Observable<LoginDto> {
        return this.http.post<LoginDto>(`${environment.apiUrl}${this.LOGIN_PATH}`, userInfo, { withCredentials: true });
    }

    getProfile(): Observable<FullUserDto> {
        return this.http.get<FullUserDto>(`${environment.apiUrl}${this.PROFILE_PATH}`, { withCredentials: true });
    }

    updateProfile(new_profile: UpdateUserDto): Observable<FullUserDto> {
        return this.http.post<FullUserDto>(`${environment.apiUrl}${this.PROFILE_UPDATE}`, new_profile, { withCredentials: true });
    }

    changePassword(password: PasswordDto): Observable<boolean> {
        return this.http.post<boolean>(`${environment.apiUrl}${this.PROFILE_PASSWORD_UPDATE}`, password, { withCredentials: true });
    }

    getProfiles(): Observable<FullUserDto[]> {
        return this.http.get<FullUserDto[]>(`${environment.apiUrl}${this.USER_PATH}/profiles`, { withCredentials: true })
    }

    deleteProfile(user_id: number): Observable<boolean> {
        return this.http.get<boolean>(`${environment.apiUrl}${this.USER_PATH}/delete/${user_id}`, { withCredentials: true });
    }

    registerProfile(newUserDto: UpdateUserDto): Observable<FullUserDto> {
        return this.http.post<FullUserDto>(`${environment.apiUrl}${this.USER_PATH}/signup`, newUserDto, { withCredentials: true });
    }
}